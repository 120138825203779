import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';

enum Style {
    Default = 'default',
    Light = 'light',
}

type Properties = {
    children?: React.ReactChild | React.ReactChild[];
    style?: Style;
    className?: string;
};

const Panel = ({ children, style, className }: Properties): React.ReactElement => {
    const renderStyle = style || Panel.defaultProps.style;

    const styles = new Map<string, string>([
        [Style.Default, 'bg-caribbean-100'],
        [Style.Light, 'bg-caribbean-50'],
    ]);

    return <div className={classNames('p-9 rounded-md', styles.get(renderStyle), className)}>{children}</div>;
};

Panel.propTypes = {
    children: PropTypes.node,
    style: PropTypes.oneOf([Style.Default, Style.Light]),
    className: PropTypes.string,
};

Panel.defaultProps = {
    style: Style.Default,
    className: '',
};

export { Panel, Style };
