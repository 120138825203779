import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';

enum Color {
    Black = 'black',
    Caribbean = 'caribbean',
    Lemon = 'meon',
}

enum Position {
    Left = 'left',
    Right = 'right',
}

type Properties = {
    color: Color;
    position: Position;
    flip?: boolean;

    className?: string;
};

const Blob = ({ color, position, flip, className }: Properties): React.ReactElement => {
    const colors = new Map<string, string>([
        [Color.Black, 'fill-black'],
        [Color.Caribbean, 'fill-caribbean'],
        [Color.Lemon, 'fill-lemon'],
    ]);

    const positions = new Map<string, string>([
        [Position.Left, ' -left-32'],
        [Position.Right, ' -right-32'],
    ]);

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 969 1591"
            className={classNames(
                'absolute w-80 -z-10 3xl:z-0',
                colors.get(color),
                positions.get(position),
                { '-scale-x-1': flip },
                className
            )}>
            <path d="M15.9 1193.5C59.8 1505 202.3 1591 337.5 1591c266.8-44.6 412-281.2 456.2-397.5 72.9-192 26.2-283.9 143.2-486.7 93.6-162.2-39-340.7-116.9-409.6C678.6 162 346.3-169.3 176.7 106.5c-212 344.8 142.5 308 0 600.3C56.1 954.2-38.9 804.2 15.9 1193.5z" />
        </svg>
    );
};

Blob.propTypes = {
    color: PropTypes.oneOf([Color.Black, Color.Caribbean, Color.Lemon]),
    position: PropTypes.oneOf([Position.Left, Position.Right]),
    flip: PropTypes.bool,

    className: PropTypes.string,
};

Blob.defaultProps = {
    flip: false,
    className: '',
};

export { Blob, Color, Position };
