import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { Hero, ImageClipping } from '../components/containers/hero';
import { ImagePosition, ImageShape, ImageSplit } from '../components/containers/image-split';
import { Panel } from '../components/containers/panel';
import { Section, Style as SectionStyle } from '../components/containers/section';
import { SectionText } from '../components/containers/section-text';
import { Style as ButtonStyle, TextButton, Type as ButtonType } from '../components/elements/text-button';
import { Blob, Color as BlobColor, Position as BlobPosition } from '../components/layout/blob';
import { Site } from '../components/shells/site';
import { Heading, Level as HeadingLevel } from '../components/typography/heading';
import { Seo } from '../components/utilities/seo';

export default (): React.ReactElement => {
    return (
        <Site>
            <Seo title="Home" />

            <Hero
                image={<StaticImage src={'../../assets/images/hero-coaching-1.jpg'} alt={''} />}
                imageClipping={ImageClipping.VerticalBlob}
                pageTitle={'Fitfoodforty'}
                pageText={
                    'Wil je werken aan een blijvende, gezondere leefstijl? Ik geef individuele, persoonlijke coaching in regio Maastricht.'
                }
            />

            <Section style={SectionStyle.Light}>
                <Heading level={HeadingLevel.Second} className={'text-center'}>
                    Leefstijlcoaching
                </Heading>
                <div className={'grid md:grid-cols-2 gap-12 mt-8'}>
                    <div className={'py-8'}>
                        <div className={'prose'}>
                            <p>
                                Bij{' '}
                                <Link to={'/leefstijlcoaching'} className={'underline'}>
                                    leefstijlcoaching
                                </Link>{' '}
                                kijken we naar de thema's <strong>stress</strong>, <strong>slaap</strong>, <strong>voeding</strong> en{' '}
                                <strong>bewegen</strong>. De combinatie hiervan bepaalt je leefstijl.
                            </p>
                            <p>
                                Als coach help ik je om hier keuzes in te maken die aansluiten bij jouw doelen. We werken hier samen aan: ik
                                ga je dus niet zeggen wat je moet doen, maar in een traject van 3 of 6 maanden pakken we dit samen aan.
                            </p>
                            <p>
                                <strong>Het resultaat?</strong> Een betere balans in je leven en leefstijl, waardoor je meer energie krijgt
                                en je prettiger in je lichaam voelt.
                            </p>
                        </div>
                    </div>
                    <Panel>
                        <div className={'prose'}>
                            <p>
                                Ik ben opgeleid en{' '}
                                <span className={'inline-block px-2 bg-lemon rounded-md font-bold'}>geaccrediteerd leefstijlcoach</span>{' '}
                                conform de regels van de{' '}
                                <a href={'https://blcn.nl/leefstijlcoach/fitfoodforty'} target={'_blank'}>
                                    Beroepsvereniging Leefstijlcoaches (BLCN)
                                </a>
                                . Ik bied <span className={'inline-block px-2 bg-lemon rounded-md font-bold'}>individuele coaching</span>{' '}
                                aan voor iedereen die klaar is voor een blijvende gedragsverandering.
                            </p>
                        </div>
                        <div className={'mt-6 text-center'}>
                            <TextButton
                                style={ButtonStyle.Secondary}
                                type={ButtonType.Link}
                                url={'/leefstijlcoaching'}
                                caption={'Meer over coaching'}
                            />
                        </div>
                    </Panel>
                </div>
                <div className={'mt-10 flex justify-center'}>
                    <a href={'https://blcn.nl/leefstijlcoach/fitfoodforty'} target={'_blank'}>
                        <StaticImage src={'../../assets/images/blcn-logo.png'} alt={''} className={'w-40'} />
                    </a>
                </div>
            </Section>

            <Blob color={BlobColor.Lemon} position={BlobPosition.Left} />

            <Section style={SectionStyle.Gradient}>
                <SectionText>
                    <Heading>Sporten of coaching via de werkgever?</Heading>
                    <p>
                        Fitfoodforty is ook partner van{' '}
                        <a href={'https://benvitaal.nl/'} target={'_blank'} className={'underline'}>
                            benVitaal
                        </a>
                        . Dit biedt medewerkers van aangesloten organisaties de mogelijkheid om{' '}
                        <a href={'/powerwalking'} className={'underline'}>
                            powerwalking lessen
                        </a>{' '}
                        te volgen of aan de slag te gaan met{' '}
                        <a href={'/leefstijlcoaching'} className={'underline'}>
                            leefstijlcoaching
                        </a>
                        .
                    </p>
                    <p className={'mt-4'}>
                        Door de samenwerking met benVitaal krijg je hiervoor een korting. Meer informatie over benVitaal en de mogelijkheden{' '}
                        <a href={'https://benvitaal.nl'} className={'underline'} target={'_blank'}>
                            vind je hier
                        </a>
                        . Een extra voordeel richting een gezonde leefstijl.
                    </p>
                </SectionText>
            </Section>

            <Section style={SectionStyle.Default}>
                <SectionText>
                    <Heading>Recepten</Heading>
                    <p>
                        Ben je op zoek naar gezonde recepten? Volg me op{' '}
                        <a href={'https://instagram.com/fitfoodforty'} target={'_blank'} rel={'noopener'} className={'underline'}>
                            Instagram
                        </a>{' '}
                        of{' '}
                        <a href={'https://facebook.com/fitfoodforty'} target={'_blank'} rel={'noopener'} className={'underline'}>
                            Facebook
                        </a>{' '}
                        voor inspiratie!
                    </p>
                    <div className={'flex justify-center gap-8 mt-8'}>
                        <a
                            href={'https://facebook.com/fitfoodforty'}
                            className={'flex items-center hover:text-caribbean'}
                            target={'_blank'}
                            rel={'noopener'}
                            title={'Volg Fitfoodforty op Facebook'}>
                            <FontAwesomeIcon icon={['fab', 'facebook-square']} size={'2x'} />
                            <span className={'hidden md:inline ml-2'}>Volg me op Facebook</span>
                        </a>
                        <a
                            href={'https://instagram.com/fitfoodforty'}
                            className={'flex items-center hover:text-caribbean'}
                            target={'_blank'}
                            rel={'noopener'}
                            title={'Volg Fitfoodforty op Instagram'}>
                            <FontAwesomeIcon icon={['fab', 'instagram']} size={'2x'} />
                            <span className={'hidden md:inline ml-2'}>Volg me op Instagram</span>
                        </a>
                    </div>
                </SectionText>
            </Section>

            <Section style={SectionStyle.Light}>
                <SectionText>
                    <Heading level={HeadingLevel.Second}>Maak een begin: powerwalking</Heading>
                    <p>
                        Wil je wat meer gaan bewegen, en wil je hier al de eerste stappen in zetten? Dan is <strong>powerwalking</strong>{' '}
                        misschien iets voor jou. Ik geef deze groepslessen op{' '}
                        <strong>maandag, woensdag, vrijdag en zaterdag</strong> in Maastricht en Berg en Terblijt.
                    </p>
                    <TextButton
                        type={ButtonType.Link}
                        style={ButtonStyle.Secondary}
                        url={'/powerwalking'}
                        caption={'Meer over powerwalking'}
                        className={'mt-6'}
                    />
                </SectionText>
            </Section>

            <ImageSplit
                imageShape={ImageShape.Rounded}
                imagePosition={ImagePosition.Right}
                content={
                    <>
                        <Heading level={HeadingLevel.Second}>Over Vicky</Heading>
                        <div className={''}>
                            <p>
                                Hoi, ik ben Vicky! Voedingsdeskundige, <strong>geaccrediteerd leefstijlcoach</strong>, <strong>gediplomeerd fitnesscoach</strong>, en gecertificeerd{' '}
                                <strong>powerwalkingcoach</strong>.
                            </p>
                        </div>
                        <TextButton
                            type={ButtonType.Link}
                            url={'mailto:vicky@fitfoodforty.nl'}
                            caption={'Vragen? Mail me!'}
                            className={'mt-6'}
                        />
                        <div className={'mt-4 text-xs text-gray-700'}>
                            Of app me via WhatsApp:{' '}
                            <a
                                href={'https://wa.me/31641777848'}
                                target={'_blank'}
                                title={'App me gerust via WhatsApp!'}
                                rel={'noreferrer'}
                                className={'underline'}>
                                06 4177 7848
                            </a>{' '}
                            <FontAwesomeIcon icon={['fab', 'whatsapp']} size={'1x'} className={'ml-0.5'} />
                        </div>
                    </>
                }
                image={<StaticImage src={'../../assets/images/section-split-vicky-3.jpg'} alt={''} />}
            />
        </Site>
    );
};
