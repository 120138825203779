import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';

enum Level {
    First = 'first',
    Second = 'second',
    Text = 'text',
}

enum Style {
    Primary = 'primary',
    Secondary = 'secondary',
}

type Properties = {
    level: Level;
    style: Style;
    children: React.ReactChild | React.ReactChild[];
    className?: string;
};

const Heading = ({ level, style, children, className }: Properties): React.ReactElement => {
    const styles = new Map<string, string>([
        [Style.Primary, 'font-bold text-2xl lg:text-4xl mb-1 lg:mb-2'],
        [Style.Secondary, 'font-bold text-xl lg:text-2xl mb-0 lg:mb-2'],
    ]);

    if (Level.First === level) {
        return <h1 className={classNames(styles.get(style), className)}>{children}</h1>;
    }

    if (Level.Second === level) {
        return <h2 className={classNames(styles.get(style), className)}>{children}</h2>;
    }

    if (Level.Text === level) {
        return <span className={classNames('block', styles.get(style), className)}>{children}</span>;
    }

    throw new Error(`Unsupported level "${level}"`);
};

Heading.propTypes = {
    level: PropTypes.oneOf(['first', 'second', 'text']),
    style: PropTypes.oneOf(['primary', 'secondary']),

    children: PropTypes.node.isRequired,
};

Heading.defaultProps = {
    level: Level.First,
    style: Style.Primary,
};

export { Heading, Level, Style };
