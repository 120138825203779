import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';

enum ImageShape {
    Rounded = 'rounded',
    Circle = 'circle',
}

enum ImagePosition {
    Left = 'left',
    Right = 'right',
}

type Properties = {
    imageShape: ImageShape;
    imagePosition: ImagePosition;

    content: React.ReactChild;
    image: React.ReactChild;
};

const renderCircleImageSplit = (imagePosition: ImagePosition, content: React.ReactChild, image: React.ReactChild): React.ReactElement => {
    return (
        <div className={'py-section sm:container sm:max-w-screen-xl'}>
            <div className={'flex flex-col sm:flex-row sm:gap-12 md:gap-14'}>
                <div className={'flex flex-col justify-center flex-initial flex-grow-2'}>
                    <div className={'px-4 sm:px-0'}>{content}</div>
                </div>
                <div
                    className={classNames('order-first flex-initial flex-shrink mb-8 sm:mb-0', {
                        'sm:order-none': ImagePosition.Right === imagePosition,
                    })}>
                    <div className={'relative w-full -z-10 overflow-hidden sm:rounded-full'}>{image}</div>
                </div>
            </div>
        </div>
    );
};

const renderRoundedImageSplit = (imagePosition: ImagePosition, content: React.ReactChild, image: React.ReactChild): React.ReactElement => {
    const isLeft = ImagePosition.Left === imagePosition;
    const isRight = ImagePosition.Right === imagePosition;

    return (
        <div className={'flex flex-col sm:flex-row items-center 2xl:justify-center py-section 2xl:container'}>
            <div
                className={classNames('flex items-center flex-grow 2xl:flex-none px-4', {
                    'justify-start': isLeft,
                    'justify-end': isRight,
                    'sm:pr-4 sm:pl-12 lg:pl-20': isLeft,
                    'sm:pl-4 sm:pr-12 lg:pr-20': isRight,
                })}>
                <div
                    className={classNames('w-full max-w-prose text-center', {
                        'sm:text-left': isLeft,
                        'sm:text-right': isRight,
                    })}>
                    {content}
                </div>
            </div>
            <div
                className={classNames('order-first sm:w-3/5 md:w-2/5 2xl:w-3/12 mb-8 sm:mb-0 px-6 sm:px-0 overflow-hidden', {
                    'sm:order-none': isRight,
                    'sm:-mr-12 2xl:mr-0': isLeft,
                    'sm:-ml-24 2xl:ml-0': isRight,
                })}>
                <div
                    className={classNames('relative w-full -z-10 overflow-hidden', {
                        'rounded-tl-half rounded-tr-full rounded-br-half rounded-bl-half': isLeft,
                        'rounded-tl-full rounded-tr-half rounded-br-half rounded-bl-half': isRight,

                        'sm:-left-12 2xl:left-0': isLeft,
                        'sm:-right-24 2xl:right-0': isRight,
                    })}>
                    {image}
                </div>
            </div>
        </div>
    );
};

const ImageSplit = ({ imageShape, imagePosition, content, image }: Properties): React.ReactElement => {
    if (ImageShape.Circle === imageShape) {
        return renderCircleImageSplit(imagePosition, content, image);
    }

    if (ImageShape.Rounded === imageShape) {
        return renderRoundedImageSplit(imagePosition, content, image);
    }

    throw new Error(`Unsupported imageShape setting: "${imageShape}"`);
};

ImageSplit.propTypes = {
    imageShape: PropTypes.oneOf([ImageShape.Circle, ImageShape.Rounded]),
    imagePosition: PropTypes.oneOf([ImagePosition.Left, ImagePosition.Right]),

    content: PropTypes.node.isRequired,
    image: PropTypes.node.isRequired,
};

ImageSplit.defaultProps = {};

export { ImageSplit, ImageShape, ImagePosition };
